<template>
  <div class="header">
    <div class="header-1">
      <el-select v-model="value" placeholder="请选择" class="select-1" @change="topchangepage">
        <el-option v-for="(item,index) in options" :key="index" :label="item" :value="item">
        </el-option>
      </el-select>
      <div class="sea-box">
        <img src="../../assets/images/home/search.png" alt="" @click="searchClick('搜索')">
        <el-input v-model="keyword" placeholder="请输入搜索内容" type="text" class="input-1" clearable
          @clear="searchClick('清除')"></el-input>
      </div>
      <!-- <div class="search-logo">
				<img src="../../assets/images/home/sousuo.png" alt="">
			</div> -->
      <div class="function">
        <div class="fun-item-1" v-for="(item,index) in allFuction" :key="item.id"
          @click="changepage(index,item.path,item.name)"
          :style="{'color': (select_fun == index ? '#DCB16B':'#FFFFFF')}">{{item.name}}</div>
      </div>
      <!-- 已登录时 -->
      <div class="user-info" v-if="userid != ''" @click="changepageUser">
        <img :src="touxiang" alt="" class="user-img" v-if="touxiang != ''">
        <img src="../../assets/images/home/yonghu.png" alt="" v-else>
        <span>{{username}}</span>
      </div>
      <!-- 未登录时 -->
      <div class="user-info login-logo" v-else @click="dialogTableVisible = true">
        <img src="../../assets/images/home/yonghu.png" alt="">
        <span>登录/注册</span>
      </div>
      <!-- 屏幕过小时 -->
      <div class="t-user-set">
        <el-container>
          <transition name='fade'>
            <div class="wrapper-gradient" v-show="isCollapse" id="topnav">
              <div class="nav-list" v-for="(item,index) in alloptions" :key="item.id"
                @click="changepage(index,item.path,item.name)">
                <span>{{item.name}}</span>
              </div>
            </div>
          </transition>
          <el-container>
            <el-header>
              <div class="box_bgd" @click="isC" ref="morelogo">
                <!-- 点击展开收起导航和切换对应图标 -->
                <i class="el-icon-s-fold" v-show="block"></i>
                <i class="el-icon-s-unfold" v-show="toggle"></i>
              </div>
            </el-header>
            <el-main>
              <router-view></router-view>
            </el-main>
          </el-container>
        </el-container>
      </div>
    </div>
    <!-- 登录弹框 -->
    <el-dialog title="" :visible.sync="dialogTableVisible">
      <div class="login-box">
        <div class="left-logo">
          <img src="@/assets/images/home/denglutu.png" alt="">
        </div>
        <div class="info-box">
          <div class="log-inf-title">登录</div>
          <input type="text" v-model="login_zh" placeholder="请输入账号" class="input">
          <input type="password" v-model="login_passeord" placeholder="请输入密码" class="input">
          <div class="log-tips">
            <div class="log-left">
              <img src="@/assets/images/login/selected.png" alt="" v-if="pass_select" @click="changeselect">
              <img src="@/assets/images/login/select.png" alt="" v-else @click="changeselect">
              <span>记住密码</span>
            </div>
            <div class="log-forget" @click="changeval('忘记密码')">忘记密码？</div>
          </div>
          <div class="log-btn" @click="loginClick">登录</div>
          <div class="prompt">还没有账号？<span @click="changeval('立即注册')">立即注册</span></div>
        </div>
      </div>
    </el-dialog>
    <!-- 登录弹框 -->

    <!-- 注册弹框 -->
    <el-dialog title="" :visible.sync="dialogTableVisible1">
      <div class="regiter-box">
        <div class="log-inf-title">注册</div>
        <div class="reg-tab">
          <div class="reg-tab-item" v-for="(item,index) in regTab" :key="index"
            :class="tabselect == index?'reg-tab-active':''" @click="changeregtab(index,item)">
            <span>{{item}}</span>
            <div class="yellow-line" v-if="tabselect == index"></div>
            <div class="zhanwei" v-else></div>
          </div>
        </div>
        <div class="reg-from-box">
          <el-form :model="RegForm" ref="RegForm" label-width="100px" class="demo-ruleForm"
            :rules="level == 1? rules : rules1">
            <div class="first-hang">
              <el-form-item label="LOGO" prop="company_logo" v-show="tabselect == 0">
                <div class="allshow_img" v-if="RegForm.company_logo">
                  <img :src="RegForm.company_logo" class="avatar">
                  <div class="shanchu_box" @click="deletelogoImg">
                    <img src="../../assets/images/person/shanchu.png" alt="">
                  </div>
                </div>
                <el-upload class="avatar-uploader" action="#" :show-file-list="false" :before-upload="beforelogoUpload"
                  v-else>
                  <img src="../../assets/images/login/shangchuan.png" alt="" class="icon-upload">
                </el-upload>
              </el-form-item>
              <el-form-item label="营业执照/ 门头照" prop="company_license" v-show="tabselect == 0">
                <div class="allshow_img allshow_img1" v-if="RegForm.company_license">
                  <img :src="RegForm.company_license" class="avatar">
                  <div class="shanchu_box" @click="deletemtzImg">
                    <img src="../../assets/images/person/shanchu.png" alt="">
                  </div>
                </div>
                <el-upload class="avatar-uploader1" action="#" :show-file-list="false"
                  :before-upload="beforeBusinessUpload" v-else>
                  <img src="../../assets/images/login/shangchuan.png" alt="" class="icon-upload">
                </el-upload>
              </el-form-item>
            </div>

            <el-form-item label="头像" prop="head_portrait" v-show="tabselect == 1">
              <div class="allshow_img" v-if="RegForm.head_portrait">
                <img :src="RegForm.head_portrait" class="avatar">
                <div class="shanchu_box" @click="deleteheaderzImg">
                  <img src="../../assets/images/person/shanchu.png" alt="">
                </div>
              </div>
              <el-upload class="avatar-uploader" action="#" :show-file-list="false"
                :before-upload="beforeHeaderImgUpload" v-else>
                <img src="../../assets/images/login/shangchuan.png" alt="" class="icon-upload">
              </el-upload>
            </el-form-item>
            <el-form-item label="公司全称" prop="company_name" v-show="tabselect == 0">
              <el-input type="text" v-model="RegForm.company_name" placeholder="请输入公司全称">
              </el-input>
            </el-form-item>
            <el-form-item label="昵称" prop="people_name" v-show="tabselect == 1">
              <el-input type="text" v-model="RegForm.people_name" placeholder="请输入昵称"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="phone">
              <el-input type="text" v-model.number="RegForm.phone" autocomplete="off" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input type="password" v-model="RegForm.password" placeholder="请输入密码"></el-input>
            </el-form-item>

            <el-form-item label="地区" prop="address">
              <div class="divwrap">
                <el-select v-model="RegForm.address.province" placeholder="选择省" @change="shengchange">
                  <el-option v-for="item in ProvinceList" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
                <el-select v-model="RegForm.address.city" placeholder="选择市" @change="shichange">
                  <el-option v-for="item in CityList" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
                <el-select v-model="RegForm.address.area" placeholder="选择区">
                  <el-option v-for="item in AreaList" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <!-- prop="salesman" -->
            <el-form-item label="业务员">
              <el-select v-model="RegForm.salesman" placeholder="请选择业务员">
                <el-option :label="item.name" :value="item.name" v-for="item in SalesmanList"
                  :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-button class="reg-btn" @click="submitzhuceForm('RegForm')">注册</el-button>
          </el-form>
        </div>
        <div class="prompt">已有账号？<span @click="changeval('立即登录')">立即登录</span></div>
      </div>
    </el-dialog>
    <!-- 注册弹框 -->

    <!-- 忘记密码弹框 -->
    <el-dialog title="" :visible.sync="dialogTableVisible2">
      <div class="forgot-box">
        <div class="log-inf-title">忘记密码</div>
        <el-form :model="forruleForm" :rules="Forrules" ref="forruleForm" label-width="80px" class="for-demo-ruleForm">
          <el-form-item label="手机号码" prop="phone">
            <el-input v-model="forruleForm.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input v-model="forruleForm.code" placeholder="请输入验证码"></el-input>
            <div class="code-btn" @click="getcode" v-show="numShow">获取验证码</div>
            <div class="code-btn" v-show="!numShow">{{num}}s</div>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input type="password" v-model="forruleForm.password" placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="qrpassword">
            <el-input type="password" v-model="forruleForm.qrpassword" placeholder="再次输入新密码"></el-input>
          </el-form-item>
          <el-button class="reg-btn" @click="ForgotPass('forruleForm')">立即创建</el-button>
        </el-form>
      </div>
    </el-dialog>
    <!-- 忘记密码弹框 -->

    <!-- 客服弹框 -->
    <div class="frame1">
      <el-dialog title="客服" :visible.sync="dialogTableVisible3" @close="closeclick">
        <Customer></Customer>
      </el-dialog>
    </div>


  </div>
</template>

<script>
  import {
    getshenglist,
    getshilist,
    getqulist,
    getywyList,
    getqntokenList,
    zhuceList,
    denglu,
    sendinfo,
    forgetmima,
    getyouhuiquan,
    getyhxinxi,
    getycdaohang
  } from "@/api/api.js"
  import VDistpicker from 'v-distpicker'
  import Customer from '@/components/customer-frame/index.vue'
  import debounce from "@/utils/debounce"
  export default {
    props: ['backkey'],
    components: {
      VDistpicker,
      Customer
    },
    data() {
      var validatearea = (rule, value, callback) => {
        if (!value.province && !value.city && !value.area) {
          return callback(new Error('请选择地区！'));
        } else if (!value.province) {
          return callback(new Error('请选择省！'));
        } else if (!value.city) {
          return callback(new Error('请选择市！'));
        } else if (!value.area) {
          return callback(new Error('请选择区！'));
        } else {
          return callback();
        }
      };
      var validatepass = (rule, value, callback) => {
        var that = this
        if (value != that.forruleForm.password) {
          return callback(new Error('新密码和确认密码不匹配！'));
        } else {
          callback();
        }
      };
      return {
        ProvinceList: [], // 省列表
        CityList: [], // 市列表
        AreaList: [], // 区列表
        SalesmanList: [], // 业务员列表
        forruleForm: {
          phone: '',
          code: '',
          password: '',
          qrpassword: ''
        },
        logoImgKey: '', // 上传logo图片所获得的key
        mtzImgKey: '', // 上传营业执照/门头照所获得的key
        HeaderImgKey: '', // 上传头像所获得的key
        action: 'https://upload-z1.qiniup.com', // 上传七牛云网址
        Forrules: {
          phone: [{
              required: true,
              message: '请输入手机号！',
              trigger: 'blur'
            },
            {
              pattern: /^1[3-9]\d{9}$/,
              message: '手机号格式不正确',
              trigger: 'blur'
            }
          ],
          code: [{
            required: true,
            message: '请输入验证码！',
            trigger: 'blur'
          }],
          password: [{
              required: true,
              message: '请输入密码！',
              trigger: 'blur'
            },
            {
              pattern: /^(?=.*[a-zA-Z0-9].*)(?=.*[a-zA-Z\\W].*)(?=.*[0-9\\W].*).{6,20}$/,
              message: '密码必须由字母和数字组成，且长度为6-20位',
              trigger: 'blur'
            },
          ],
          qrpassword: [{
            required: true,
            message: '再次输入新密码！',
            trigger: 'blur'
          }, {
            validator: validatepass,
            trigger: 'blur',
            required: true
          }],
        },
        allFuction: [{
            id: 0,
            path: '/',
            name: '首页'
          }, {
            id: 1,
            path: '/about-company',
            name: '关于公司'
          },
          {
            id: 2,
            path: '',
            name: '客服'
          },
          {
            id: 3,
            path: '/personal-center',
            name: '我的收藏'
          },
          {
            id: 4,
            path: '/personal-center',
            name: '个人中心'
          }
        ],
        options: ['婚礼摄影', '婚礼摄像', '微电影', '婚礼旅拍', '商业拍摄', '企业宣传片', '纪录片', '政府宣传片', '酒店展示'],
        touxiang: '',
        alloptions: [{
            id: 1,
            path: '/',
            name: '首页'
          },
          {
            id: 2,
            path: '/collection-cases',
            name: '微电影'
          },
          {
            id: 3,
            path: '/collection-cases',
            name: '纪录片'
          },
          {
            id: 4,
            path: '/wedding-video',
            name: '婚礼摄像'
          },
          {
            id: 5,
            path: '/wedding-photography',
            name: '婚礼摄影'
          },
          // {
          // 	path: '/college-imaging',
          // 	name: '影像学院'
          // },
          {
            id: 6,
            path: '/collection-cases',
            name: '案例锦集'
          },
          {
            id: 7,
            path: '/collection-cases',
            name: '婚礼旅拍'
          },
          {
            id: 8,
            path: '/collection-cases',
            name: '商业拍摄'
          },
          {
            id: 9,
            path: '/hotel-display',
            name: '酒店展示'
          },
          {
            id: 10,
            path: '/collection-cases',
            name: '企业宣传片'
          },
          {
            id: 11,
            path: '/collection-cases',
            name: '政府宣传片'
          },
          {
            id: 12,
            path: '/about-company',
            name: '关于公司'
          },
          {
            id: 13,
            path: '/join-us',
            name: '加入我们'
          },
          {
            id: 14,
            path: '/personal-center',
            name: '我的收藏'
          },
          {
            id: 15,
            path: '/personal-center',
            name: '个人中心'
          },
        ],
        isCollapse: false, //导航栏默认为展开
        toggle: false, //第二个图标默认隐藏
        block: true, //默认显示第一个图标
        value: '',
        keyword: '',
        width: document.documentElement.clientWidth, //实时屏幕宽度
        dialogTableVisible: false, //登录弹框显示
        pass_select: false,
        dialogTableVisible1: false, //注册弹框显示
        dialogTableVisible2: false, // 忘记密码提示
        dialogTableVisible3: false, //客服
        regTab: ['公司注册', '个人注册'],
        tabselect: 0, // 注册类型
        RegForm: {
          company_logo: '',
          company_license: '',
          head_portrait: '',
          company_name: '',
          people_name: '',
          phone: '',
          password: '',
          address: {
            province: '',
            city: '',
            area: ''
          },
          salesman: '',
        },
        rules: {
          company_logo: [{
            required: true,
            message: '请上传logo！',
            trigger: 'change'
          }],
          company_license: [{
            required: true,
            message: '请上传营业执照/ 门头照！',
            trigger: 'change'
          }],
          company_name: [{
            required: true,
            message: '请输入公司全称！',
            trigger: 'blur'
          }],
          phone: [{
              required: true,
              message: '请输入手机号！',
              trigger: 'blur'
            },
            {
              pattern: /^1[3-9]\d{9}$/,
              message: '手机号格式不正确',
              trigger: 'blur'
            }
          ],
          password: [{
              required: true,
              message: '请输入密码！',
              trigger: 'blur'
            },
            {
              pattern: /^(?=.*[a-zA-Z0-9].*)(?=.*[a-zA-Z\\W].*)(?=.*[0-9\\W].*).{6,20}$/,
              message: '密码必须由字母和数字组成，且长度为6-20位',
              trigger: 'blur'
            },
          ],
          address: [{
            validator: validatearea,
            trigger: 'blur',
            required: true
          }],
          // salesman: [{
          // 	required: true,
          // 	message: '请选择业务员！',
          // 	trigger: 'change'
          // }]
        },
        rules1: {
          people_name: [{
            required: true,
            message: '请输入昵称！',
            trigger: 'blur'
          }],
          phone: [{
              required: true,
              message: '请输入手机号！',
              trigger: 'blur'
            },
            {
              pattern: /^1[3-9]\d{9}$/,
              message: '手机号格式不正确',
              trigger: 'blur'
            }
          ],
          password: [{
              required: true,
              message: '请输入密码！',
              trigger: 'blur'
            },
            {
              pattern: /^(?=.*[a-zA-Z0-9].*)(?=.*[a-zA-Z\\W].*)(?=.*[0-9\\W].*).{6,20}$/,
              message: '密码必须由字母和数字组成，且长度为6-20位',
              trigger: 'blur'
            },
          ],
          address: [{
            validator: validatearea,
            trigger: 'blur',
            required: true
          }]
        },
        numShow: true,
        num: 60, //倒计时初始值
        select_fun: localStorage.getItem('headerIndex'),
        current_location: '',
        level: 1, // 注册类型：1.公司 2.个人
        login_zh: '', //登录弹框，账号
        login_passeord: '', //登录弹框 密码
        username: '', // 用户昵称
        userid: ''
      }
    },
    watch: {
      width(val, oldval) { // 监听屏幕的实时宽度
        let that = this;
        that.toggle = false;
        that.block = true;
        that.isCollapse = false;
      },
      select_fun(val, oldval) { // 顶部菜单选择
        var that = this
        that.select_fun = val;
      },
    },
    updated() {
      var that = this
      that.select_fun = localStorage.getItem('headerIndex');
    },
    mounted() {
      localStorage.setItem('headerIndex', 0);
    },
    created() {
      var that = this
      // 查看是否已登录
      var info = JSON.parse(localStorage.getItem('user_info'));
      if (info && info != null) {
        // 已登录
        that.getUserInfoList();

      } else { // 未登录
        // 大屏幕时
        for (let i = 0; i < that.allFuction.length; i++) {
          var obj = that.allFuction[i];
          if (obj.name == '我的收藏') {
            that.allFuction.splice(i, 1);
            i -= 1;
          }
          if (obj.name == '个人中心') {
            that.allFuction.splice(i, 1);
            i -= 1;
          }
        }
        // 小屏幕
        for (let j = 0; j < that.alloptions.length; j++) {
          var obj1 = that.alloptions[j];
          if (obj1.name == '我的收藏') {
            that.alloptions.splice(j, 1);
            j -= 1;
          }
          if (obj1.name == '个人中心') {
            that.alloptions.splice(j, 1);
            j -= 1;
          }
        }
      }
      // 判断是否记住密码
      var select = localStorage.getItem('pass_select');
      if (select && select != null) {
        if (select == 'false') {
          that.pass_select = false;
        }
        if (select == 'true') { //记住密码时
          that.pass_select = true;
          var phone = localStorage.getItem('phone');
          var password = localStorage.getItem('password');
          if (phone && phone != '') {
            that.login_zh = phone;
          }
          if (password && password != '') {
            that.login_passeord = password;
          }
        }
      }
      that.getprovincesList(); // 获取省份列表
      that.getsalesmanList(); // 获取业务员列表
      var topselect = localStorage.getItem('topselect');
      if (topselect) {
        that.value = topselect;
      }

      // 获取侧边隐藏导航栏
      that.getyclist();
    },
    methods: {
      getyclist() {
        var that = this
        var obj = {}
        getycdaohang(obj).then(res => {
          if (res.code == 1) {
            var data = res.data;
            console.log(data)
            if (data.length > 0) {
              data.forEach((item, index) => {
                that.options = that.options.filter(item1 => item1 != item.name);
                that.alloptions = that.alloptions.filter(item2 => item2.name != item.name);
              })
            }

          }
        })
      },
      // 获取个人信息
      getUserInfoList() {
        var that = this
        var obj = {}
        getyhxinxi(obj).then(res => {
          if (res.code == 1) {
            var data = res.data;
            that.touxiang = data.logo; // 头像
            that.username = data.name; //名称
            that.userid = data.id;
          }
          if (res.code == -1) {
            that.setMessage()
            localStorage.removeItem('user_info')
            let timeout1 = setTimeout(() => {
              that.$nextTick(() => {
                // location.href = '/';
								that.$router.push({
									path: '/',
								}).catch(err => {
									// that.$forceUpdate();
								})
              })
              clearTimeout(timeout1);
            }, 1500)
          }
        })
      },
      setMessage: debounce(function() {
        this.$message.error('登录状态已过期重新登录!')
      }, 500),
      // 点击头像跳转个人中心
      changepageUser() {
        var that = this
        that.$router.push({
          path: '/personal-center',
          query: {
            personsiderIndex: 0
          }
        })
      },
      // 点击搜索
      searchClick(name) {
        var that = this
        if (name == '搜索') {
          if (that.value) {
            that.$emit('SendKeyword', that.keyword)
          } else {
            that.$message.error('请先选择栏目！');
          }
        } else {
          that.$emit('SendKeyword', that.keyword)
        }

      },
      // 关闭弹窗时
      closeclick() {
        var that = this
        that.dialogTableVisible3 = false;
        document.getElementsByClassName('v-modal')[0].style.display = 'none';
        document.getElementsByClassName('el-popup-parent--hidden')[0].style.overflow = 'auto';
      },
      // 点击立即创建
      ForgotPass(formName) {
        var that = this
        that.$refs[formName].validate((valid) => {
          if (valid) {
            that.ForgotPassWord();
          } else {
            return false;
          }
        });
      },
      // 忘记密码
      ForgotPassWord() {
        var that = this
        var obj = {
          phone: that.forruleForm.phone,
          code: that.forruleForm.code,
          password: that.forruleForm.password,
          re_password: that.forruleForm.qrpassword
        }
        forgetmima(obj).then(res => {
          if (res.code == 1) {
            that.$message.success(res.msg)
            that.dialogTableVisible2 = false;
          } else {
            that.$message.error(res.msg)
          }
        })
      },
      topchangepage(e) {
        var that = this
        that.value = e;
        localStorage.setItem('topselect', e)
        localStorage.setItem('operate', '首次');
        localStorage.setItem('selectIndex', ''); //  取消个人中心侧边栏选择项
        localStorage.removeItem('tab_index'); // 取消订单tab选项
        localStorage.setItem('headerIndex', null); // 取消顶部菜单的选择项
        that.alloptions.forEach((item, index) => {
          var category_id = '';
          if (item.name == e) {
            var select_name = '';
            var select_index = 0;
            category_id = index;

            if (e == '案例锦集') {
              select_name = '请选择';
              select_index = 3;
              category_id = 1;
            }
            if (e == '微电影') {
              select_name = e;
              select_index = 4;
              // category_id = 1;
            }
            if (e == '婚礼旅拍') {
              select_name = e;
              select_index = 5;
              // category_id = 2;
            }
            if (e == '商业拍摄') {
              select_name = e;
              select_index = 6;
              // category_id = 3;
            }
            if (e == '企业宣传片') {
              select_name = e;
              select_index = 7;
              // category_id = 4;
            }
            if (e == '纪录片') {
              select_name = e;
              select_index = 8;
              // category_id = 5;
            }
            if (e == '政府宣传片') {
              select_name = e;
              select_index = 9;
              // category_id = 6;
            }
            if (e == '案例锦集' || e == '微电影' || e == '婚礼旅拍' || e == '商业拍摄' || e == '企业宣传片' || e ==
              '纪录片' || e == '政府宣传片') {
              localStorage.setItem('operate', '首次');
              localStorage.setItem('category_id', category_id); // 分类id
              localStorage.setItem('classify_select', select_name); // 分类名称
              localStorage.setItem('type_select', 0); // 初始化类型选择
              // location.href = item.path + '?typename=' + select_name + '&siderIndex=' + select_index;
              that.$router.push({
              	path: item.path,
              	query: {
              		typename: select_name,
              		siderIndex: select_index
              	}
              }).catch(err => {
              	// that.$forceUpdate();
              })
            } else {
              // location.href = item.path;
              // 跳转页面
              that.$router.push({
              	path: item.path,
              }).catch(err => {
              	// that.$forceUpdate();
              })
            }
          }
        })
      },
      // 点击登录
      loginClick() {
        var that = this
        // 判断是否记住密码
        if (that.pass_select) {
          // 记住密码
          localStorage.setItem('phone', that.login_zh)
          localStorage.setItem('password', that.login_passeord)
          localStorage.setItem('pass_select', true)
        } else {
          localStorage.setItem('phone', '')
          localStorage.setItem('password', '')
          localStorage.setItem('pass_select', false)
        }
        var obj = {
          phone: that.login_zh,
          password: that.login_passeord
        }
        denglu(obj).then(res => {
          if (res.code == 1) {
            var data = res.data;
            that.$message.success({
              message: res.msg,
              duration: 5000,
            });
            that.touxiang = data.logo; // 头像
            that.username = data.name; //名称
            var token = data.token;
            // that.$router.push({path:'/'})
            //本地存储登录信息
            localStorage.setItem('user_info', JSON.stringify(data));
            // 查看是否已领取优惠券
            that.getcoupon();
            that.dialogTableVisible = false; // 关闭登录弹框
          } else {
            that.$message.error(res.msg);
          }
        })
      },
      // 优惠券
      getcoupon() {
        var that = this
        var obj = {}
        getyouhuiquan(obj).then(res => {
          if (res.code == 1) { // 有优惠券
            localStorage.setItem('homeframe', true);
          } else {
            localStorage.setItem('homeframe', false);
          }
          that.$router.go(0); //刷新页面
        })
      },
      // 注册时提交表格
      submitzhuceForm(formName) {
        var that = this

        if (that.level == 1) {
          // 公司注册
          var obj = {
            level: that.level,
            logo: that.logoImgKey,
            head_photo: that.mtzImgKey,
            name: that.RegForm.company_name,
            phone: that.RegForm.phone,
            password: that.RegForm.password,
            province: that.RegForm.address.province,
            city: that.RegForm.address.city,
            area: that.RegForm.address.area,
            salesman: that.RegForm.salesman
          }
        }
        if (that.level == 2) {
          // 个人注册
          var obj = {
            level: that.level,
            logo: that.HeaderImgKey, //头像
            head_photo: '', // 营业执照
            name: that.RegForm.people_name, //公司名称/昵称
            phone: that.RegForm.phone,
            password: that.RegForm.password,
            province: that.RegForm.address.province,
            city: that.RegForm.address.city,
            area: that.RegForm.address.area,
            salesman: that.RegForm.salesman
          }
        }
        that.$refs[formName].validate((valid) => {
          if (valid) {
            that.registerList(obj,that.level);
          } else {
            return false;
          }
        });

      },
      // 注册
      registerList(obj,level) {
        var that = this
        zhuceList(obj).then(res => {
          if (res.code == 1) {
            if(level == 1){
              this.$alert('平台管理员需审核，请等待2个工作日或电话咨询15931697521', '提示', {
                confirmButtonText: '确定',
                center: true,
                showInput:false,
              });
            }
            if(level == 2){
              this.$message.success(res.msg);

            }
            that.dialogTableVisible1 = false; // 关闭注册弹框
            // 点击切换的时候重置表单
            this.$nextTick(() => {
              this.$refs.RegForm.resetFields(); //清空表单
            })
            
            that.RegForm.address.province = '',
              that.RegForm.address.city = '',
              that.RegForm.address.area = '',
              // 清空需向后台传递的图片key
              that.logoImgKey = '';
            that.mtzImgKey = '';
            that.HeaderImgKey = '';
            // that.dialogTableVisible = true; // 打开登录弹框
          } else {
            this.$message.error(res.msg);
          }
        })
      },
      // 获取省列表
      getprovincesList() {
        var that = this
        var obj = {}
        getshenglist(obj).then(res => {
          if (res.code == 1) {
            that.ProvinceList = res.data;
          }
        })
      },
      // 省份变化时
      shengchange(e) {
        var that = this
        // 省发生变化时,对应的市和区都发生变化,故清空市  区/县的选择
        that.RegForm.address.city = '';
        that.RegForm.address.area = '';
        if (e) {
          // 根据所选择的省份获取对应的市
          that.getcityList(e);
        }
      },
      // 获取市列表
      getcityList(e) {
        var that = this
        var obj = {
          id: e
        }
        getshilist(obj).then(res => {
          if (res.code == 1) {
            that.CityList = res.data;
          }
        })
      },
      // 市变化时
      shichange(e) {
        var that = this
        // 市发生变化,则相应的区也变化,故清空区的选择
        that.RegForm.address.area = '';
        if (e) {
          // 根据所选择的市获取对应的区/县
          that.getareaList(e);
        }
      },
      // 获取区/县列表
      getareaList(e) {
        var that = this
        var obj = {
          id: e
        }
        getqulist(obj).then(res => {
          if (res.code == 1) {
            that.AreaList = res.data;
          }
        })
      },
      // 业务员列表
      getsalesmanList() {
        var that = this
        var obj = {}
        getywyList(obj).then(res => {
          if (res.code == 1) {
            that.SalesmanList = res.data;
          }
        })
      },
      // 上传logo图片
      beforelogoUpload(file) {
        var that = this
        that.RegForm.company_logo = URL.createObjectURL(file);
        // 获取图片的token
        var obj = {}
        getqntokenList(obj).then(res => {
          if (res.code == 1) {
            var token = res.data;
            that.uploadImg('logo', token, file);
          }
        })
        // 阻止本地默认上传事件
        return false
      },
      // 上传营业执照/门头照
      beforeBusinessUpload(file) {
        var that = this
        that.RegForm.company_license = URL.createObjectURL(file);
        var obj = {}
        getqntokenList(obj).then(res => {
          if (res.code == 1) {
            var token = res.data;
            that.uploadImg('营业执照', token, file);
          }

        })
        return false
      },
      // 上传头像
      beforeHeaderImgUpload(file) {
        var that = this
        that.RegForm.head_portrait = URL.createObjectURL(file);
        var obj = {}
        getqntokenList(obj).then(res => {
          if (res.code == 1) {
            var token = res.data;
            that.uploadImg('头像', token, file);
          }

        })
        return false
      },
      // 上传图片到七牛云
      uploadImg(name, token, file) {
        var that = this
        //配置请求头
        const config = {
          headers: {
            "Content-Type": "multipart/form-data"
          },
        };
        var key = new Date().getTime() + Math.floor(Math.random() * 24)
        const formdata = new FormData();
        formdata.append("file", file);
        formdata.append("token", token);
        formdata.append("key", key);
        that.$http.post(that.action, formdata, config).then((res) => {
          if (name == 'logo') {
            that.logoImgKey = res.data.key; // 根据后台需要修改拼接
          }
          if (name == '营业执照') {
            that.mtzImgKey = res.data.key;
          }
          if (name == '头像') {
            that.HeaderImgKey = res.data.key;
          }
        }).catch(() => {

        })
      },
      // 删除logo图片
      deletelogoImg() {
        var that = this
        that.RegForm.company_logo = ''; // 清空图片显示
        that.logoImgKey = ''; // 清空需向后端传递的参数
      },
      // 删除营业执照/门头照
      deletemtzImg() {
        var that = this
        that.RegForm.company_license = ''; // 清空图片显示
        that.mtzImgKey = ''; // 清空需向后端传递的参数
      },
      // 删除上传的头像
      deleteheaderzImg() {
        var that = this
        that.RegForm.head_portrait = ''; // 清空图片显示
        that.HeaderImgKey = ''; // 清空需向后端传递的参数
      },
      // 验证码倒计时
      getcode() {
        var that = this
        // 发送短信
        if (that.forruleForm.phone) {
          if (/^1[3-9]\d{9}$/.test(that.forruleForm.phone)) {
            that.SendSMS();
            that.numShow = false
            var interval = setInterval(() => {
              --that.num
            }, 1000)
            setTimeout(() => {
              clearInterval(interval)
              that.numShow = true
              // 再将num设为初始值,否则第二次点击时,倒计时从0开始
              that.num = 60
            }, 60000)
          } else {
            that.$message.error('手机号格式不正确！')
          }
        } else {
          that.$message.error('请输入手机号码！')
        }
      },
      // 发送短信
      SendSMS() {
        var that = this
        var obj = {
          mobile: that.forruleForm.phone
        }
        sendinfo(obj).then(res => {
          if (res.code == 1) {
            that.$message.success(res.msg);
          } else {
            that.$message.error(res.msg);
          }
        })
      },
      // 动态控制展开与收起和切换对应图标
      isC() {
        var that = this
        this.isCollapse = !this.isCollapse;
        this.$emit('videoStatus', !this.isCollapse)
        this.toggle = !this.toggle; // 关闭
        this.block = !this.block; // 展开
        if (this.block == true) { // 控制导航划入划出
          var elm = document.getElementById('topnav').classList;
          elm.add('wrapper-gradient1')
        } else {
          var elm = document.getElementById('topnav').classList;
          elm.remove('wrapper-gradient1');
        }

      },
      // 是否记住密码
      changeselect() {
        var that = this
        that.pass_select = !that.pass_select;
        // 判断是否记住密码
        if (that.pass_select) {
          // 记住密码
          localStorage.setItem('phone', that.login_zh)
          localStorage.setItem('password', that.login_passeord)
          localStorage.setItem('pass_select', true)
        } else {
          localStorage.setItem('phone', '')
          localStorage.setItem('password', '')
          localStorage.setItem('pass_select', false)
        }
      },
      // 控制弹框的显示
      changeval(name) {
        var that = this
        if (name == '立即注册') {
          that.dialogTableVisible = false;
          that.dialogTableVisible1 = true;
        }
        if (name == '立即登录') {
          that.dialogTableVisible = true;
          that.dialogTableVisible1 = false;
        }
        if (name == '忘记密码') {
          that.dialogTableVisible = false;
          that.dialogTableVisible2 = true;
        }
      },

      // 跳转页面
      changepage(index, path, name) {
        var that = this
        localStorage.setItem('topselect', ''); //  取消顶部搜索筛选项
        localStorage.setItem('selectIndex', ''); //  取消个人中心侧边栏选择项
        localStorage.removeItem('tab_index'); // 初始化订单中心选择
        // 关闭右侧菜单框
        that.isCollapse = false;
        that.toggle = false; //第二个图标默认隐藏
        that.block = true; //默认显示第一个图标
        // 切换选中项
        that.select_fun = index;

        if (path && path != '') {
          // 顶部菜单处理
          if (name == '我的收藏') {
            var personsiderIndex = 2;
          }
          if (name == '个人中心') {
            var personsiderIndex = 0;
          }

          localStorage.setItem('person_index', personsiderIndex); // 控制顶部菜单选择
          // 右侧菜单栏处理
          var select_name = '';
          var select_index = 0;
          var category_id = ''; //分类选中
          if (name == '案例锦集') {
            select_name = '微电影';
            select_index = 3;
            category_id = 1;
          }
          if (name == '微电影') {
            select_name = name;
            select_index = 4;
            category_id = 1;
          }
          if (name == '婚礼旅拍') {
            select_name = name;
            select_index = 5;
            category_id = 2;
          }
          if (name == '商业拍摄') {
            select_name = name;
            select_index = 6;
            category_id = 3;
          }
          if (name == '企业宣传片') {
            select_name = name;
            select_index = 7;
            category_id = 4;
          }
          if (name == '纪录片') {
            select_name = name;
            select_index = 8;
            category_id = 5;
          }
          if (name == '政府宣传片') {
            select_name = name;
            select_index = 9;
            category_id = 6;
          }
          if (name == '案例锦集' || name == '微电影' || name == '婚礼旅拍' || name == '商业拍摄' || name == '企业宣传片' || name ==
            '纪录片' || name == '政府宣传片') {
            localStorage.setItem('operate', '首次')
            localStorage.setItem('category_id', category_id); //分类id
            localStorage.setItem('classify_select', select_name); // 分类名称
            localStorage.setItem('type_select', 0); // 初始化类型选择
            that.$router.push({
            	path: path,
            	query: {
            		typename: select_name,
            		siderIndex: select_index
            	}
            }).catch(err => {
            	// that.$forceUpdate();
            })
            // location.href = path + '?typename=' + select_name + '&siderIndex=' + select_index;
          } else {
            // 跳转页面
            // location.href = path + '?personsiderIndex=' + personsiderIndex;
            that.$router.push({
            	path: path,
            	query: {
            		personsiderIndex: personsiderIndex
            	},
            }).catch(err => {
            	// that.$forceUpdate();
            })
          }

        } else {
          if (name == '客服') {
            that.dialogTableVisible3 = true;
            return
          }
        }
        // 本地储存选中项
        localStorage.setItem('headerIndex', index);
        // 与顶部选择框保持一致
        if (name == '首页' || name == '案例锦集' || name == '加入我们' || name == '关于公司' || name == '个人中心' || name ==
          '我的收藏') {
          localStorage.setItem('topselect', '')
        } else {
          localStorage.setItem('topselect', name)
        }
      },
      // 选择注册类型
      changeregtab(index, name) {
        var that = this
        // 控制选中项
        that.tabselect = index;
        if (name == '公司注册') {
          that.level = 1;
        } else {
          that.level = 2;
        }
        // 点击切换的时候重置表单
        this.$nextTick(() => {
          this.$refs.RegForm.resetFields(); //清空表单
        })
        that.RegForm.address.province = '',
          that.RegForm.address.city = '',
          that.RegForm.address.area = '',
          // 清空需向后台传递的图片key
          that.logoImgKey = '';
        that.mtzImgKey = '';
        that.HeaderImgKey = '';
      }
    }
  }
</script>

<style lang="scss">
  @import '../../assets/css/home.scss';
  @import './index.scss';
</style>

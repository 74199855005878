<template>
	<div class="footer">
		
		<div class="f-characteristic">
			<div class="f-c-item" v-for="item in traitArr">
				<img :src="item.url" alt="">
				<span>{{item.name}}</span>
			</div>
		</div>
		<div class="f-hui-main">
			<div class="f-info">
				<div class="f-logo">
					<img src="../../assets/images/home/dibu_logo.png" alt="">
					<span>一家值得信任的公司</span>
					<span>选择我们，更放心、更安心、更省心！</span>
				</div>
				<div class="f-contact">
					<span>联系我们</span>
					<div class="phone">
						{{tele_phone}}
						<div class="call-logo" @click="callPhone">
							<img src="../../assets/images/home/bodadianhua.png" alt="">
							<span>拨打电话</span>
						</div>
					</div>
					<span>联系地址</span>
					<span>{{company_info.address}}</span>
				</div>
				<div class="f-code">
					<!-- <div class="code-item">
						<img :src="company_info.wechat_gzh" alt="">
						<span>公众号</span>
					</div>
					<div class="code-item">
						<img :src="company_info.microblog" alt="">
						<span>微博</span>
					</div> -->
					<div class="code-item">
						<img :src="company_info.douyin" alt="">
						<span>抖音</span>
					</div>
					<div class="code-item">
						<img :src="company_info.wechat" alt="">
						<span>小红书</span>
					</div>
				</div>
			</div>
			<div class="copyright">{{company_info.bq}}
				<a href="https://beian.miit.gov.cn/" target="_blank" @click="golink('https://beian.miit.gov.cn/')">{{company_info.record}}</a>
        <div class="foot-js">
          <span>技术支持：</span>
          <a href="http://www.zonho.top/" target="_blank" @click="golink('http://www.zonho.top/')">中赫传媒</a>
        </div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		getwzxinxiList
	}from '@/api/api.js'
	export default{
		data(){
			return{
				traitArr:[
					{
						url:require('../../assets/images/home/tongxiang.png'),
						name:'同享成功'
					},
					{
						url:require('../../assets/images/home/gongtongfazhan.png'),
						name:'共同发展'
					},
					{
						url:require('../../assets/images/home/jingying.png'),
						name:'专业化精英'
					},
					{
						url:require('../../assets/images/home/shuchu.png'),
						name:'高效高质的输出'
					}
				],
				tele_phone:'',
				company_info:{}
			}
		},
		created() {
			var that = this
			that.getWebsiteList();
		},
		methods:{
			
      golink(url){
        // window.location.href=url
        window.open(url,'_blank')
      },
			// 获取网站信息
			getWebsiteList(){
				var that = this
				var obj = {}
				getwzxinxiList(obj).then(res => {
					if(res.code == 1){
						that.company_info = res.data;
						that.tele_phone = that.company_info.phone;
						localStorage.setItem('company_info',JSON.stringify(that.company_info))
					}else{
						that.$message.error(res.msg);
					}
				})
			},
			// 点击拨打电话
			callPhone(){
				var that = this
				 window.location.href = "tel://" + that.tele_phone;
			}
		}
	}
</script>

<style lang="scss">
	@import './index.scss';
	
</style>

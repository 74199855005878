import Vue from 'vue'

import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'

Vue.use(VueRouter)



const routes = [{
		path: '/',
		name: 'Home',
		component: Home,
		meta:{
			title:'MG影像',
			keepAlive:true
		}
	},
	{ //新闻中心列表
		path: '/news',
		name: 'News',
		component: () => import('../views/Home/News.vue'),
		meta:{
			title:'新闻中心-MG影像',
			keepAlive:true
		}
	},
	{ //新闻中心详情
		path: '/news-details',
		name: 'News-details',
		component: () => import('../views/Home/News-details.vue'),
		meta:{
			title:'新闻中心-MG影像',
			keepAlive:true
		}
	},
	{ // 影像学院
		path: '/college-imaging',
		name: 'College-Imaging',
		component: () => import('../views/College-Imaging/College-Imaging.vue'),
		meta:{
			title:'影像学院-MG影像',
			keepAlive:true
		}
	},
	{ // 婚礼摄像
		path: '/wedding-video',
		name: 'Wedding-Video',
		component: () => import('../views/Wedding-Video/Wedding-Video.vue'),
		meta:{
			title:'婚礼摄像-MG影像',
			keepAlive:true
		}
	},
	{ // 婚礼摄像--详情
		path: '/video-details',
		name: 'Video-Details',
		component: () => import('../views/Wedding-Video/Video-Details.vue'),
		meta:{
			title:'婚礼摄像-MG影像',
			keepAlive:true
		}
	},
	{ // 婚礼摄影
		path: '/wedding-photography',
		name: 'Wedding-Photography',
		component: () => import('../views/Wedding-Photography/Wedding-Photography.vue'),
		meta:{
			title:'婚礼摄影-MG影像',
			keepAlive:true
		}
	},
	{ // 婚礼摄影--详情
		path: '/photography-details',
		name: 'Photography-Details',
		component: () => import('../views/Wedding-Photography/Photography-Details.vue'),
		meta:{
			title:'婚礼摄影-MG影像',
			keepAlive:true
		}
	},
	{ //案例锦集 微电影 婚礼旅拍 商业拍摄  企业宣传片  纪录片  政府宣传片
		path: '/collection-cases',
		name: 'Collection-Cases',
		component: () => import('../views/Collection-Cases/Collection-Cases.vue'),
		meta:{
			title:'',
			keepAlive:true
		}
	},
	{ //案例锦集 微电影 婚礼旅拍 商业拍摄  企业宣传片  纪录片  政府宣传片 详情
		path: '/case-details',
		name: 'Case-details',
		component: () => import('../views/Collection-Cases/Case-details.vue'),
		meta:{
			title:'',
			keepAlive:true
		}
	},
	{ //酒店展示
		path: '/hotel-display',
		name: 'Hotel-Display',
		component: () => import('../views/Hotel-Display/Hotel-Display.vue'),
		meta:{
			title:'酒店展示-MG影像',
			keepAlive:true
		}
	},
	{ //酒店详情
		path: '/hotel-details',
		name: 'Hotel-Details',
		component: () => import('../views/Hotel-Display/Hotel-Details.vue'),
		meta:{
			title:'酒店展示-MG影像',
			keepAlive:true
		}
	},
	{ //加入我们
		path: '/join-us',
		name: 'Join-Us',
		component: () => import('../views/Join-Us/Join-Us.vue'),
		meta:{
			title:'加入我们-MG影像',
			keepAlive:true
		}
	},
	{ // 关于公司
		path: '/about-company',
		name: 'About-Company',
		component: () => import('../views/About-Company/About-Company.vue'),
		meta:{
			title:'关于公司-MG影像',
			keepAlive:true
		}
	},
	{ // 个人中心
		path: '/personal-center',
		name: 'Personal-Center',
		component: () => import('../views/Personal-Center/Personal-Center.vue'),
		meta:{
			title:'个人中心-MG影像',
			keepAlive:true
		}
	},
	{ // 图片详情
		path: '/photo-details',
		name: 'Photo-Details',
		component: () => import('../views/Wedding-Photography/photo-details.vue'),
		meta:{
			title:'婚礼摄影-MG影像',
			keepAlive:true
		}
	}
]

const router = new VueRouter({
	// mode: 'history',
	mode: 'hash',
	routes,
	scrollBehavior (to, from, savedPosition) { // 每次跳转页面都会到最顶部
		console.log(savedPosition,'savedPosition')
			if (savedPosition) {
			  return savedPosition
			} else {
			  return { x: 0, y: 0 }
			}
	  }
})
router.beforeEach((to,from,next) => {
	var that = this
	// 修改浏览器显示名称
	document.title = to.meta.title;
	// if(from.path != '/' && to.path == '/'){
	// 	location.href = to.path;
	// }else{
	// 	next();
	// }
	 to.meta.keepAlive = true;
	next();
})
export default router

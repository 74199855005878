<template>
	<div class="hot-list hot-list1">
		<div class="title">
			<div>TOP热榜</div>
			<div class="hui-bottom">TOP Hot List</div>
		</div>
		<div class="all-swiper">
			<div class="swiper-button-prev swiper-button-prev1" slot="button-prev"></div>
			<swiper class="swiper" :options="swiperOption">
				<swiper-slide v-for="item in list" :key="item.id">
					<img :src="item.image" alt="" @click="changepage(item.id,item.name)">
					<div class="lb-text-box" @click="changepage(item.id,item.name)">
						<span class="lb-name">{{item.name}}</span>
						<div class="item-title">
							<span v-for="(item1,index1) in item.tag" :key="index1" v-if="index1<1&&item.tag.length > 1">{{item1}} |</span>
							<span v-for="(item1,index1) in item.tag" :key="index1" v-if="index1<1&&item.tag.length == 1">{{item1}}</span>
							<span v-for="(item1,index1) in item.tag" :key="index1" v-if="index1 == 1">{{item1}}</span>
							<span v-if="item.tag.length > 2">...</span>
							<!-- <div v-for="item1 in item.tag">
								<span class="lb-line">|</span>
							</div> -->
						</div>
					</div>
				</swiper-slide>
			</swiper>
			<div class="swiper-button-next swiper-button-next1" slot="button-next"></div>
		</div>
	</div>
</template>

<script>
	import 'swiper/dist/css/swiper.css'
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper'
	export default {
		name: 'hot-list1',
		props: ['list'],
		components: {
			swiper,
			swiperSlide,
		},

		data() {
			return {
				width: document.documentElement.clientWidth, //实时屏幕宽度
				swiperOption: {
					slidesPerView: 5,
					spaceBetween: 30,
          slidesPerGroup: 1,
					loop: true,
					autoplay:{
					  delay:0
					},
					speed:3000,
					navigation: {
						nextEl: '.swiper-button-next1',
						prevEl: '.swiper-button-prev1'
					},
					on: {
						resize: function(params) {
							var that = this
							if (that.width <= 576) {
								that.params.slidesPerView = 2;
							} else if (that.width <= 768) {
								that.params.slidesPerView = 3;
							} else if (that.width <= 1000) {
								that.params.slidesPerView = 4;
							} else {
								that.params.slidesPerView = 5;
							}
							this.update(); //窗口变化时，更新Swiper的一些属性，如宽高等
						}
					}
				},
			}
		},
		watch: {

		},

		methods: {
			changepage(id, name) {
				var that = this
				that.$router.push({
					path: '/photography-details',
					query: {
						operate: '婚礼摄影',
						id: id,
						name: name,
					}
				})
			}
		},
		created() {
			var that = this
			if (that.width <= 576) {
				that.swiperOption.slidesPerView = 2;
			} else if (that.width <= 768) {
				that.swiperOption.slidesPerView = 3;
			} else if (that.width <= 1048) {
				that.swiperOption.slidesPerView = 4;
			} else {
				that.swiperOption.slidesPerView = 5;
			}
		},
		mounted() {
			var that = this;


		},
	}
</script>

<style lang="scss">
	@import './index.scss';
</style>

<template>
	<div class="classification">
		<div class="bigbox" @click="changepage(path)">
			<div class="back-img" ref="photoRef">
				<img :src="img_url" alt="" >
			</div>
			<div class="mask">
				<div class="typy-name">
					<div class="jb-color">{{chinese_name}}</div>
					<div class="hui-color hui-color-1" v-if="chinese_name == '影视商学院'">{{english_name}}</div>
					<div class="hui-color" v-else >{{english_name}}</div>
				</div>
				<div class="explain" v-show="js_text && js_text != ''">
					{{js_text}}
					<!-- 使针对想要从事摄影摄像的小白，大学生毕业，没有找到合适工作，热爱喜欢影像制作，年龄21—28岁，男女不限，进行15—30天的培训，通过考核即可成为摄影师，入职率100%！一旦成为摄影师，公司即可直签！享受底薪保障，实现摄影剪辑梦想！ -->
				</div>
				<img src="../../assets/images/home/jinneiye.png" alt="">
			</div>
		</div>
		<div class="huang-box"></div>
	</div>
</template>

<script>
	export default {
		name: 'classification',
		props:['img_url','chinese_name','english_name','js_text','path'],
		data(){
			return{
			}
		},
		watch: {
		},
		methods:{
			changepage(path){
				var that = this
				if(that.chinese_name == '案例锦集分类'){
					var name = '微电影';
					localStorage.setItem('operate','首次')
					// location.href = path +'?typename=' + name + '&siderIndex=3';
					that.$router.push({
						path,
						query: {
							typename: name,
							siderIndex: 3
						}
					})
				}else{
					// location.href = path;
					that.$router.push({
						path
					})
				}
				
			}
		},
		mounted() {
			
		}
	}
</script>

<style lang="scss">
	@import './index.scss';
	
</style>
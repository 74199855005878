import request from '@/utils/request.js'

// // 查询对应班级
// export const studentqueryById = (query) => {
//   return request({
//     method: 'get',
//     url: '/api/index/province',
//     params: query
//   })
// }
// 酒店展示列表页
export const getjiudianList = (query) => {
  return request({
    method: 'post',
    url: '/api/hotel/lists',
    data: query
  })
}
// 酒店展示详情
export const getjiudianxqList = (query) => {
  return request({
    method: 'post',
    url: '/api/hotel/detail',
    data: query
  })
}
// 加入我们
export const jiaruList = (query) => {
  return request({
    method: 'post',
    url: '/api/user/joinUs',
    data: query
  })
}
// 案例锦集类型
export const getanlileixingList = (query) => {
  return request({
    method: 'post',
    url: '/api/Goods/series',
    data: query
  })
}
// 案例锦集列表
export const getanliList = (query) => {
  return request({
    method: 'post',
    url: '/api/goods/goodsList',
    data: query
  })
}
// 案例锦集详情
export const getanlidetails = (query) => {
  return request({
    method: 'post',
    url: '/api/goods/detail',
    data: query
  })
}
// 新闻列表
export const getxinwenList = (query) => {
  return request({
    method: 'post',
    url: '/api/news/lists',
    data: query
  })
}
// 新闻列表详情
export const getxwxiangqing = (query) => {
  return request({
    method: 'post',
    url: '/api/news/detail',
    data: query
  })
}
// 婚礼摄影列表
export const gethlsheyingList = (query) => {
  return request({
    method: 'post',
    url: '/api/user/photographer',
    data: query
  })
}
// 婚礼摄像列表
export const gethlshexiangList = (query) => {
  return request({
    method: 'post',
    url: '/api/user/cameraman',
    data: query
  })
}
// 收藏
export const shoucangList = (query) => {
  return request({
    method: 'post',
    url: '/api/user/collect',
    data: query
  })
}
// 取消收藏
export const removeshoucangList = (query) => {
  return request({
    method: 'post',
    url: '/api/user/uncollect',
    data: query
  })
}
// 摄影师或摄像师详情
export const getsheyingDetails = (query) => {
  return request({
    method: 'post',
    url: '/api/user/cameraDetail',
    data: query
  })
}
// 我的收藏
export const getshoucangList= (query) => {
  return request({
    method: 'post',
    url: '/api/user/myCollect',
    data: query
  })
}
// 下单
export const addorderList = (query) => {
  return request({
    method: 'post',
    url: '/api/order/add',
    data: query
  })
}
// 订单列表
export const getdingdanList = (query) => {
  return request({
    method: 'post',
    url: '/api/order/lists',
    data: query
  })
}
// 订单详情
export const getdingdandetailList = (query) => {
  return request({
    method: 'post',
    url: '/api/order/detail',
    data: query
  })
}
// 首页
export const getshouyelList = (query) => {
  return request({
    method: 'post',
    url: '/api/index/lists',
    data: query
  })
}
// 摄影图片详情
export const getsytupianlList = (query) => {
  return request({
    method: 'post',
    url: '/api/user/getImg',
    data: query
  })
}
// 婚礼摄影可定机位
export const getjiweilist= (query) => {
  return request({
    method: 'post',
    url: '/api/user/photographer_number',
    data: query
  })
}
// 婚礼摄影判断当前日期是否可下单
export const getdqnum = (query) => {
  return request({
    method: 'post',
    url: '/api/order/judgeStock',
    data: query
  })
}
// 婚礼摄像可定机位
export const getvideojiweilist= (query) => {
  return request({
    method: 'post',
    url: '/api/user/cameraman_number',
    data: query
  })
}
// 婚礼摄像判断当前日期是否可下单
export const getvideodqnum= (query) => {
  return request({
    method: 'post',
    url: '/api/order/cameraStock',
    data: query
  })
}
//立即下单-计算定金
export const getdjmoney= (query) => {
  return request({
    method: 'post',
    url: '/api/order/compute_deposit',
    data: query
  })
}
<template>
	<div class="customer-box">
		<img :src="img_url" alt="">
		<span class="cus-name">微信客服</span>
    <span>客服工作时间：9:00到6:00</span>
		<span>微信扫描二维码进行咨询</span>
	</div>
</template>

<script>
	export default{
		data() {
			return{
				img_url:''
			}
		},
		created() {
			var that = this
			var obj = JSON.parse(localStorage.getItem('company_info'))
			that.img_url = obj.kefu;
		},
		methods:{

		},
	}
</script>

<style lang="scss">
	.customer-box{
		width: 400px;
    margin: 0 auto;
		display: flex;
		align-items: center;
		flex-direction: column;
		font-size: 16px;
		font-weight: 400;
		color: #333333;
    line-height: 30px;
		cursor: pointer;
		.cus-name{
			font-size: 18px;
			font-weight: bold;
			color: #333333;
			margin: 15px 0;
		}
		img{
			width: 169px;
			height: 169px;
			display: block;
		}
	}
	@media screen and (max-width:576px) {
		.customer-box{
			width: 260px;

		}
	}
	@media screen and (min-width: 576px) and (max-width:769px) {
		.customer-box{
			width: 355px;

		}
	}
</style>

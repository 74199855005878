<template>
	<div id="app">
		<Header @SendKeyword='getKeyword' @videoStatus='getstatus'></Header>
		<keep-alive v-if="$route.meta.keepAlive" >
			<router-view :keyword="keyword" :videoStatus='video_status' :key="$route.path" />
		</keep-alive>
		<router-view :keyword="keyword" :videoStatus='video_status' v-if="!$route.meta.keepAlive" :key="$route.path"/>
		<Footer></Footer>
		
		<div class="f-top" @click="scrollToTop">
			<img src="./assets/images/home/top.png" alt="" />
			<span>返回顶部</span>
		</div>
	</div>
</template>
<script>
	import Header from '@/components/header/index.vue'
	import Footer from '@/components/footer/index.vue'
	export default {
		components: {
			Header,
			Footer
		},
		
		data() {
			return {
				keyword:'',
				video_status:true,
			}
		},
		created(){
			console.log('daozhela')
		},
		mounted() {
			
		},
		watch:{
	
		},
		methods:{
			// 接收视频播放状态
			getstatus(val){
				this.video_status = val;
			},
			// 接收顶部搜索框内容
			getKeyword(val){
				this.keyword = val;
			},
			scrollToTop() {
				// 平滑滚动到页面顶部
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			},
		}
	}
</script>
<style lang="scss">
	@import 'assets/css/public.scss';
	
	#app {
		font-family: "微软雅黑";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
 .f-top{
		 position: fixed;
		 right: 20px;
		 bottom: 10%;
		 background: #1E1E1E;
		 width: 90px;
		 height: 90px;
		 border-radius: 50%;
		 z-index: 99;
		 display: flex;
		 cursor: pointer;
		 align-items: center;
		 justify-content: center;
		 flex-direction: column;
		 font-size: 14px;
		 font-weight: 400;
		 color: #FFFFFF;
		 img{
			 width: 40px;
			 height: auto;
			 display: block;
		 }
	 }
	body {
		margin: 0;
		overflow-x: hidden;
		padding-right:0 !important;
		// 安卓解决屏幕蓝一下
		-webkit-tap-highlight-color:rgba(0,0,0,0);
	}
	//IOS
	a:focus,input:focus,p:focus,div:focus{ 
	    -webkit-tap-highlight-color:rgba(0,0,0,0); 
	    -webkit-user-modify:read-write-plaintext-only; 
	}
	p{
		margin: 0;
	}
</style>

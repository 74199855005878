<template>
	<div class="ysTeachVideo">
		<div id="video" ref="video">
			<div class="all-player">
				<!-- :autoplay="false"-->
				<!-- 自定义封面图 -->
				<div class="poster" v-show="covershow">
					<img :src="cover_img" alt="">
				</div>
        <!-- mp4_url -->
				<vue-core-video-player ref="videoPlayer" src="https://media.w3.org/2010/05/sintel/trailer.mp4" :muted="false" :title="title" preload="none"
					:loop="false" controls="auto" :autoplay="false" :cover='cover_img' @play="onPlayerPlay($event)"
					@pause="onPlayerPause($event)" @ended="onPlayerEnded($event)"
					@loadeddata="onPlayerLoadeddata($event)" @timeupdate="onPlayerTimeupdate($event)" v-if="videoshow"  show-fullscreen-btn="true">
				</vue-core-video-player>
			</div>
		</div>
	</div>

</template>
</template>

<script>
	import HLSCore from '@core-player/playcore-hls'
	export default {
		name:'ysTeachVideo',
		props:['url','cover_img'],
		data() {
			return {
				mp4_url: [{
						src: this.url, //https://media.w3.org/2010/05/sintel/trailer.mp4
						resolution: 360,
					},
					// {
					// 	src: 'https://aliyun.oss.careyshop.cn/uploads/files/20191113/9bbf7ac9-a452-445b-8152-4443bc3505e8.mp4?type=aliyun',
					// 	resolution: 720,
					// },
					// {
					// 	src: 'https://aliyun.oss.careyshop.cn/uploads/files/20191113/9bbf7ac9-a452-445b-8152-4443bc3505e8.mp4?type=aliyun',
					// 	resolution: '4k',
					// }
				],
				// cover: this.cover_img,
				title: "",
				HLSCore,
				covershow:true,
				videoshow:false,
			}
		},
		mounted() {
			var that = this
		},
		created() {
			var that = this
			if(that.url != ''&&that.url){
				that.videoshow = true;
			}else{
				that.videoshow = false;
			}
				// that.videoshow = false;
				// setInterval(()=>{
				// 		that.videoshow = true;
				// },2000)
		},
		watch: {
			url(val,oldval){
				this.url = val
				this.mp4_url[0].src = val;
				this.videoshow = true;
			}
		},
		methods: {
			onPlayerLoadeddata(e) {
				// console.log('当播放器开始加载第一帧时候触发',e)
			},
			onPlayerPlay(e) {
				// console.log('表示当播放器开始播放或者通过 play() 方法从暂停状态恢复',e)
				var that = this
				// that.covershow = false; // 隐藏封面图
        this.$refs.videoPlayer.pause();
        window.open(this.url,'_blank')
			},
			onPlayerTimeupdate(e) {
				// console.log('当播放的媒体 currenttime 发生改变时候触发',e)
				var that = this
				// 获取全屏状态
				var name = this.$refs.videoPlayer.$refs['vcp-el'].className;
				var num = name.indexOf('fullscreen');
				if(num && num != -1){ // 全屏
				var elm = document.getElementsByClassName('vcp-container')[0];
				elm.classList.add('fullscreen');

				}

			},
			onPlayerPause(e) {
				// console.log('当播放器停止播放的时候触发',e)
				var that = this
				that.covershow = true; // 隐藏封面图
			},
			onPlayerEnded(e) {
				// console.log('当媒体播放结束时候触发',e)
			},

		},
		beforeDestroy(){
		}
	};
</script>

<style lang="scss">
	.ysTeachVideo {
		margin-top: 40px;
		position: relative;
		.all-player {
			width: 100%;
			height: max-content;
			position: relative;
			.poster{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 13;
				img{
					display: block;
					width: 100%;
					height: 100%;
				}
			}
			.vcp-container video{z-index: 14;}
			.vue-core-video-player-layers{
				z-index: 14;
			}
			.vcp-m-dashboard{
				z-index: 15;
			}
			.play-pause-layer{
				z-index: 999;
			}
			// 暂停按钮的位置
			.play-pause-layer .btn-play svg{
				margin-left: 15px;
				margin-top: -10px;
			}
			.play-pause-layer .btn-control svg{
				padding-top: 10px;
			}
			.play-pause-layer .btn-control:hover{
				transition: all 0.4s;
				transform: scale(1.1);
			}
			.vcp-layer{
				// 播放，暂停按钮的颜色
				path{
					fill: rgba(0,0,0,0.63);
					d: path("M 23.5 11 L 47 48 H 0 Z");
				}
				rect{
					fill: rgba(0,0,0,0.63);
					height: 35px;
				 }

			}
			.vcp-dashboard {
				z-index: 14;
				width: 100%;
				margin-left: 0 !important;
				left: 0;
			}
		}
	}

	.play-pause-layer .btn-control:before {
		width: 60px !important;
		height: 60px !important;
		top: 60% !important;
		left: 60% !important;
	}

	.video-player {
		width: 100% !important;
		height: 770px !important;
	}
</style>

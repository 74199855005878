import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VDistpicker from 'v-distpicker'
Vue.component('v-distpicker', VDistpicker);

import axios from 'axios'
Vue.prototype.$http = axios

/* 引入aos动画库相关文件 */
import AOS from 'aos'
import 'aos/dist/aos.css'
/* aos动画初始化 */
AOS.init({
    duration: 1000,
    easing:"ease-in-out-back"
});

import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.use(VueCoreVideoPlayer, {
  lang: 'zh-CN'
})

import 'default-passive-events'
// 七牛云
import * as qiniu from 'qiniu-js'
Vue.use(qiniu)

// import VideoPlayer from 'vue-video-player'
// import 'video.js/dist/video-js.css'

// Vue.use(VideoPlayer)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
	<div class="l-tab-box">
		<div class="l-tab-item" v-for="(item,index) in options" :key="index"
			:class="selectIndex == index ? 'l-tab-active':''" @click="changeIndex(index,item.path,item.name)">
			{{item.name}}</div>
	</div>
</template>

<script>
	import {
		getycdaohang,
	} from '@/api/api.js'
	export default {
		props: ['tab_selectIndex'],
		data() {
			return {
				selectIndex: 0,
				options: [{
					path: '/',
					name: '首页'
				}, {
					path: '/wedding-photography',
					name: '婚礼摄影'
				},
				{
					path: '/wedding-video',
					name: '婚礼摄像'
				},
				// {
				// 	path: '/college-imaging',
				// 	name: '影像学院'
				// }, 
				{
					path: '/collection-cases',
					name: '案例锦集'
				}, 
				{
					path: '/collection-cases',
					name: '微电影'
				}, 
				{
					path: '/collection-cases',
					name: '婚礼旅拍'
				},
				{
					path: '/collection-cases',
					name: '商业拍摄'
				}, {
					path: '/collection-cases',
					name: '企业宣传片'
				}, 
				{
					path: '/collection-cases',
					name: '纪录片'
				},
				{
					path: '/collection-cases',
					name: '政府宣传片'
				}, {
					path: '/hotel-display',
					name: '酒店展示'
				}, {
					path: '/join-us',
					name: '加入我们'
				}],
			}
		},
		watch:{
			tab_selectIndex(val,oldval){
				var that = this
				that.selectIndex = that.tab_selectIndex;
			}
		},
		created() {
			var that = this
			that.selectIndex = that.tab_selectIndex;
			// 获取侧边隐藏导航栏
			that.getyclist();
		},
		methods: {
			getyclist(){
				var that = this
				var obj = {}
				getycdaohang(obj).then(res => {
					if (res.code == 1) {
						var data = res.data;
						if(data.length > 0){
							data.forEach((item,index)=>{
								that.options = that.options.filter(item1=>item1.name != item.name);
							})
						}
						
					}
				})
			},
			changeIndex(index, path, name) {
				var that = this
				that.selectIndex = index;
				localStorage.setItem('options',JSON.stringify(that.options)); //  取消顶部搜索筛选项
				localStorage.setItem('topselect',''); //  取消顶部搜索筛选项
				localStorage.setItem('selectIndex',''); //  取消个人中心侧边栏选择项
				localStorage.setItem('headerIndex', null); // 取消顶部菜单的选择项
				localStorage.removeItem('tab_index'); // 初始化订单中心选择
				var category_id = '';
				if (name == '案例锦集') {
					name = '案例锦集'
					category_id = 0;
				}
				if (name == '微电影') {
					category_id = 1;
				}
				if (name == '婚礼旅拍') {
					category_id = 2;
				}
				if (name == '商业拍摄') {
					category_id = 3;
				}
				if (name == '企业宣传片') {
					category_id = 4;
				}
				if (name == '纪录片') {
					category_id = 5;
				}
				if (name == '政府宣传片') {
					category_id = 6;
				}
				
				if (name == '案例锦集' || name == '微电影' || name == '婚礼旅拍' || name == '商业拍摄' || name == '企业宣传片' || name ==
					'纪录片' || name == '政府宣传片') {
					localStorage.setItem('operate','首次');
					localStorage.setItem('category_id',category_id); // 分类id
					localStorage.setItem('classify_select',name); // 分类名称
					localStorage.setItem('type_select',0); // 初始化类型选择
					that.$router.push({
						path: path,
						query: {
							typename: name,
							siderIndex:index
						}
					}).catch(err => {
						// that.$forceUpdate();
					})
					// location.href = path + '?typename=' + name + '&siderIndex=' + index;
				} else {
					// location.href = path;
					that.$router.push({
						path: path
					}).catch(err => {
						// that.$forceUpdate();
					})
				}
				// 与顶部选择框保持一致
				if(name == '首页' || name == '案例锦集' || name == '加入我们'){
					localStorage.setItem('topselect','')
				}else{
					localStorage.setItem('topselect',name)
				}
			},
		}
	}
</script>

<style lang="scss">
	@import '../../assets/css/home.scss';

	.l-tab-box {
		// width: 180px;
		margin-top: 73px;
		width: 9.3%;
		background: #F7F7F7;
		display: flex;
		flex-direction: column;
		position: relative;
		height: max-content;
		position: sticky;
		top: 0;
		flex: none;
		cursor: pointer;

		.l-tab-item {
			width: 100%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 15px;
			font-weight: 400;
			color: #333333;
		}

		.l-tab-item:hover {
			background: linear-gradient(265deg, #f3cf88, #fce8b8);
			;
		}

		.l-tab-active {
			background: linear-gradient(265deg, #FECF73, #FFE4A2);
			font-size: 16px;
			font-weight: 600;
		}
	}
</style>

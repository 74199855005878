<template>
	<div class="home">
		<div class="publicize-top">
			<img :src="topImg" alt="" v-if="top_video == ''">
			<div class="video-box video-box1" v-if="top_video != ''" >
				<div class="poster" v-show="showposter1" @click="openvideo1" :class="showvideo1 ? '':'show-fmt'">
					<img :src="topImg" alt="">
					<div class="bf-btn">
						<img src="@/assets/images/home/bofang.png" alt="">
					</div>
				</div>
				<video class="video" :src="top_video" controls :poster="topImg" webkit-playsinline='true'
					playsinline='true' x5-video-player-type="h5-page" ref="myVideo1" v-show="showvideo1"
					@pause="onPause1" @play="onPlay1"></video>
			</div>
			<div class="pageview">
				<img src="@/assets/images/home/tongji.png" alt="">
				<span>浏览量：{{index_info.views}}</span>
			</div>
		</div>
		<div class="main-box">
			<Sidder :tab_selectIndex='tab_selectIndex'></Sidder>
			<div class="m-subject-box">

				<HomeClassification :img_url="allarr[0].img_url" :chinese_name="allarr[0].chinese_name"
					:english_name="allarr[0].english_name" :path="allarr[0].path" :js_text="allarr[0].js_text">
				</HomeClassification>
				<HotList1 class="hot-box" :list='index_info.photo_list' v-if="hotShow"></HotList1>
				<HomeClassification :img_url="allarr[1].img_url" :chinese_name="allarr[1].chinese_name"
					:english_name="allarr[1].english_name" :path="allarr[1].path" :js_text="allarr[1].js_text">
				</HomeClassification>
				<HotList v-if="hotShow" class="hot-box" :list='index_info.camera_list' @overRset="overRset()"></HotList>
				<!-- 	<HomeClassification :img_url="allarr[2].img_url" :chinese_name="allarr[2].chinese_name" :english_name="allarr[2].english_name" :path="allarr[2].path"
					:js_text="allarr[2].js_text"></HomeClassification> -->
				<!-- 轮播2 -->
				<!-- <swiper class="multigraph-swiper" :options="swiperOption"  v-if="morebanners.length > 0">
					<swiper-slide v-for="(item,index) in morebanners" :key="index">
						<img :src="item" alt="">
					</swiper-slide>
				</swiper> -->
				<HomeClassification :img_url="allarr[2].img_url" :chinese_name="allarr[2].chinese_name"
					:english_name="allarr[2].english_name" :path="allarr[2].path" :js_text="allarr[2].js_text">
				</HomeClassification>
				<VideoPlayer v-if="videoshow" :url='index_info.case_video' :cover_img="cover" class="video-big">
				</VideoPlayer>
				<div class="video-box">
					<div class="poster" v-show="showposter" @click="openvideo">
						<img :src="cover" alt="">
						<div class="bf-btn">
							<img src="@/assets/images/home/bofang.png" alt="">
						</div>
					</div>
					<video class="video" src="" controls :poster="cover" webkit-playsinline='true'
						playsinline='true' x5-video-player-type="h5-page" ref="myVideo" v-show="showvideo"
						@pause="onPause" @play="onPlay"></video>
				</div>
			</div>
			<div class="r-set-box" v-show="kfshow">
				<div class="r-set-item" @click="dialogTableVisible = true">
					<img src="@/assets/images/home/kefu.png" alt="">
					<span>客服</span>
				</div>
				<!-- <div class="r-set-item r-item-1">
					<img src="@/assets/images/home/xinxi.png" alt="">
					<span>消息</span>
				</div> -->
			</div>
		</div>
		<!-- 新闻中心 -->
		<div class="news-box">
			<div class="n-bg-img">
				<img :src="new_image" alt="">
			</div>
			<!-- 蒙层 -->
			<div class="n-bg-mask">
				<div class="n-white-box">
					<span>新闻中心</span>
					<div class="n-bai-text">News Center</div>
				</div>
				<div class="n-list">
					<div class="n-list-item" v-for="item in index_info.news_list" :key="item.id"
						@click="changepage('新闻详情',item.id)">
						<div class="n-item-left">
							<span>{{item.date}}</span>
							<span class="n-time">{{item.time}}</span>
						</div>
						<div class="n-item-right">{{item.title}}</div>
					</div>
					<div class="n-more" @click="changepage('新闻列表')">查看更多
						<img src="@/assets/images/home/gengduo.png" alt="">
						<img src="@/assets/images/home/huang_gengduo.png" alt="" class="hover-img">
					</div>
				</div>
			</div>
		</div>
		<!-- 屏幕过小时 -->
		<div class="n-list-1">
			<div class="n-list1-item" v-for="item in index_info.news_list" :key="item.id"
				@click="changepage('新闻详情',item.id)">
				<div class="n-item1-left">
					<span>{{item.date}}</span>
					<span>{{item.time}}</span>
				</div>
				<div class="n-item1-right">{{item.title}}</div>
			</div>
			<div class="n-more-1" @click="changepage('新闻列表')">
				查看更多
				<img src="@/assets/images/home/gengduo.png" alt="">
				<img src="@/assets/images/home/huang_gengduo.png" alt="" class="hover-img">
			</div>
		</div>


		<!-- 优惠券弹框 -->
		<div class="frame2">
			<el-dialog title="" :visible.sync="dialogTableVisible1" @close="closeclick('优惠券')">
				<div class="coupon-box">
					<span class="coupon-name">优惠券</span>
					<div class="cou-price">￥<span>{{certificate.money}}</span></div>
					<div class="lq-button" @click="drawclick(certificate.id)"></div>
				</div>
			</el-dialog>
		</div>
		<!-- 客服弹框 -->
		<div class="frame1">
			<el-dialog title="客服" :visible.sync="dialogTableVisible" @close="closeclick('客服')">
				<Customer></Customer>
			</el-dialog>
		</div>
	</div>

</template>

<script>
	import HomeClassification from '@/components/home-classification/index.vue'
	import HotList from '@/components/hot-list/index.vue'
	import HotList1 from '@/components/hot-list1/index.vue'
	import VideoPlayer from '@/components/video-player/index.vue'
	import Customer from '@/components/customer-frame/index.vue'
	import Sidder from '@/components/sidder/index.vue'
	import 'swiper/dist/css/swiper.css'
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper'
	import {
		getyouhuiquan,
		getmoney
	} from '@/api/api.js'
	import {
		getshouyelList
	} from '@/api/joggle.js'
	export default {
		name: 'Home',
		props: ['videoStatus'],
		components: {
			HomeClassification,
			HotList,
			HotList1,
			VideoPlayer,
			swiper,
			Customer,
			swiperSlide,
			Sidder
		},
		data() {
			return {
        hotShow: false,
				dialogTableVisible: false,
				dialogTableVisible1: false,
				topImg: '',
				top_video: '',
				top_video1:'',
				tab_selectIndex: 0,
				allarr: [{
						img_url: '',
						chinese_name: '婚礼摄影',
						english_name: 'Wedding photography',
						path: '/wedding-photography',
						js_text: ''
					},
					{
						img_url: '',
						chinese_name: '婚礼摄像',
						english_name: 'Wedding Video',
						path: '/wedding-video',
						js_text: ''
					},
					// {
					// 	img_url: require('@/assets/images/home/beijingtu.png'),
					// 	chinese_name: '影视商学院',
					// 	english_name: 'Film and Television Business School',
					// 	path:'/college-imaging',
					// 	js_text: '使针对想要从事摄影摄像的小白，大学生毕业，没有找到合适工作，热爱喜欢影像制作，年龄21—28岁，男女不限，进行15—30天的培训，通过考核即可成为摄影师，入职率100%！一旦成为摄影师，公司即可直签！享受底薪保障，实现摄影剪辑梦想！',
					// },
					{
						img_url: '',
						chinese_name: '案例锦集分类',
						english_name: 'Case Collection Classification',
						path: '/collection-cases',
						js_text: ''
					}
				],
				swiperOption: {
					// loop: true,
					slidesPerView: 5,
					slidesPerColumn: 2,
					spaceBetween: 20,
					loopFillGroupWithBlank: true,
					slidesPerGroup: 1,
					pagination: {
						nextEl: '',
						prevEl: '',
						type: 'bullets',
						clickable: true
					},
					autoplay: {
						delay: 3000, //自动切换的时间间隔，单位ms
						stopOnLastSlide: false, //当切换到最后一个slide时停止自动切换
						disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
					},
					on: {
						resize: function(params) {
							var that = this
							if (that.width <= 576) {
								that.params.slidesPerView = 2;
							} else if (that.width <= 768) {
								that.params.slidesPerView = 3;
							} else if (that.width <= 1000) {
								that.params.slidesPerView = 4;
							} else {
								that.params.slidesPerView = 5;
							}
							this.update(); //窗口变化时，更新Swiper的一些属性，如宽高等
						}
					}
				},
				morebanners: [],
				width: document.documentElement.clientWidth, //实时屏幕宽度
				certificate: '', //优惠券信息
				new_image: '', //新闻列表背景图
				index_info: {}, // 首页信息
				videoshow: false, // 视频插件是否显示
				cover: '',
				showposter: true,
				showvideo: true,
				video_status: true, // 点击顶部导航栏关闭视频
				kfshow: true,

				showposter1: true,
				showvideo1: false,
				video_status: true, // 点击顶部导航栏关闭视频
				kfshow: true,
				cz_btn:false

			}
		},
		watch: {
			// 点击顶部下拉关闭视频
			videoStatus(val, oldval) {
				if (val == false) {
					this.$refs.myVideo.pause();
					this.showposter = true;
					this.showvideo = false;
					this.kfshow = true;

					this.$refs.myVideo1.pause();
					this.showposter1 = true;
					this.showvideo1 = false;
				}
			},
			width(val, oldval) {
				let that = this;
				that.toggle = false;
				that.block = true;
				that.isCollapse = false;
			},

		},
		mounted() {
			var that = this;
		},
		activated() {
			var that = this

			if (that.videoStatus != undefined) {
				that.video_status = that.videoStatus;
				// console.log(that.video_status)
				if (that.video_status == false) {
					this.showposter = true;
					this.showvideo = false;

					this.showposter1 = true;
					this.showvideo1 = false;

				}
			}
			// 清空顶部选择
			localStorage.removeItem('headerIndex');
			if (that.width <= 576) {
				that.swiperOption.slidesPerView = 2;
			} else if (that.width <= 768) {
				that.swiperOption.slidesPerView = 3;
			} else if (that.width <= 1048) {
				that.swiperOption.slidesPerView = 4;
			} else {
				that.swiperOption.slidesPerView = 5;
			}
			// 控制首页优惠券的显示
			var status = localStorage.getItem('homeframe');
			if (status == 'true') {
				that.dialogTableVisible1 = true;
				// 获取优惠券
				that.getcoupon();
			} else {
				that.dialogTableVisible1 = false;
			}
			// 获取首页数据
			that.getList();

		},

		methods: {
      overRset(){
        // this.hotShow = false
        // setTimeout(() => {
        //   this.hotShow = true
        // }, 10)
      },
			onPlay() {
				this.showposter = false;
				this.showvideo = true;
				this.kfshow = false;
			},
			onPlay1() {
				this.showposter1 = false;
				this.showvideo1 = true;
			},
			onPause() {
				this.showposter = true;
				this.showvideo = false;
				this.kfshow = true;
			},
			onPause1() {
				this.showposter1 = true;
				this.showvideo1 = false;
			},
			openvideo() {
				// this.showposter = false;
				// this.showvideo = true;
				// this.$refs.myVideo.play();
        window.open(this.index_info.case_video,'_blank')
			},
			openvideo1() {
        window.open(this.top_video,'_blank')
				// this.showposter1 = false;
				// this.showvideo1 = true;
				// this.$refs.myVideo1.play();
			},
			getList() {
				var that = this
				var obj = {}
				getshouyelList(obj).then(res => {
					if (res.code == 1) {
						that.index_info = res.data;
            that.hotShow = true;
						that.top_video = that.index_info.banner_list[0].video; //banner视频
						that.topImg = that.index_info.banner_list[0].image; // 头图

						that.cover = that.index_info.video_image; //封面图
						that.videoshow = true;
						that.new_image = that.index_info.news_image;
						that.allarr.forEach(item => {
							if (item.chinese_name == '婚礼摄像') {
								item.img_url = that.index_info.shexiang_image;
							}
							if (item.chinese_name == '婚礼摄影') {
								item.img_url = that.index_info.sheying_image;
							}
							if (item.chinese_name == '案例锦集分类') {
								item.img_url = that.index_info.anli_image;
							}
						})
						that.index_info.news_list.forEach((item, index) => {
							var lastindex = item.create_time.lastIndexOf('-');
							item.time = item.create_time.slice(0, lastindex);
							item.date = item.create_time.slice(lastindex + 1);
						})


					}
				})
			},

			// 点击领取优惠券，并关闭弹框
			drawclick(id) {
				var that = this
				// 领取优惠券
				that.receivecoupons(id);
			},
			// 领取优惠券
			receivecoupons(id) {
				var that = this
				var obj = {
					coupon_id: id
				}
				getmoney(obj).then(res => {
					if (res.code == 1) {
						// 关闭弹窗
						that.dialogTableVisible1 = false;
						that.$message.success(res.msg);
						localStorage.setItem('homeframe', that.dialogTableVisible1);
						document.getElementsByClassName('v-modal')[0].style.display = 'none';
						document.getElementsByClassName('el-popup-parent--hidden')[0].style.overflow = 'auto';
					} else {
						that.$message.error(res.msg);
						that.dialogTableVisible1 = true;
						localStorage.setItem('homeframe', that.dialogTableVisible1);
					}
				})
			},
			// 获取优惠券
			getcoupon() {
				var that = this
				var obj = {}
				getyouhuiquan(obj).then(res => {
					if (res.code == 1) {
						that.certificate = res.data;
					}
				})

			},
			// 跳转页面
			changepage(name, id) {
				var that = this
				if (name == '新闻列表') {
					that.$router.push({
						path: '/news'
					})
				}
				if (name == '新闻详情') {
					that.$router.push({
						path: '/news-details',
						query: {
							id: id
						}
					})
				}
			},
			closeclick(name) {
				var that = this
				// 点击蒙层关闭弹窗但不领取优惠券
				if (name == '优惠券') {
					that.dialogTableVisible1 = false;
				}
				if (name == '客服') {
					that.dialogTableVisible = false;
				}
				document.getElementsByClassName('v-modal')[0].style.display = 'none';
				document.getElementsByClassName('el-popup-parent--hidden')[0].style.overflow = 'auto';
			}

		},
		// 离开页面事件
		beforeDestroy() {
			var that = this
		},
	}
</script>
<style lang="scss">
	@import '@/assets/css/home.scss';
</style>

import request from '@/utils/request.js'

// // 查询对应班级
// export const studentqueryById = (query) => {
//   return request({
//     method: 'get',
//     url: '/api/index/province',
//     params: query
//   })
// }

// 获取省份
export const getshenglist = (query) => {
  return request({
    method: 'post',
    url: '/api/index/province',
    data: query
  })
}
// 获取市
export const getshilist = (query) => {
  return request({
    method: 'post',
    url: '/api/index/city',
    data: query
  })
}
// 获取区
export const getqulist = (query) => {
  return request({
    method: 'post',
    url: '/api/index/area',
    data: query
  })
}
// 获取业务员列表
export const getywyList = (query) => {
  return request({
    method: 'post',
    url: '/api/user/salesman',
    data: query
  })
}
// 获取七牛云token
export const getqntokenList = (query) => {
  return request({
    method: 'post',
    url: '/api/index/getToken',
    data: query
  })
}
// 注册
export const zhuceList = (query) => {
  return request({
    method: 'post',
    url: '/api/user/register',
    data: query
  })
}
// 登录
export const denglu = (query) => {
  return request({
    method: 'post',
    url: '/api/user/login',
    data: query
  })
}
//获取用户信息（个人中心）
export const getyhxinxi = (query) => {
  return request({
    method: 'post',
    url: '/api/user/getUser',
    data: query
  })
}
// 修改个人信息
export const edityhxinxi = (query) => {
  return request({
    method: 'post',
    url: '/api/user/editUser',
    data: query
  })
}
// 修改密码
export const editmima = (query) => {
  return request({
    method: 'post',
    url: '/api/user/editPassword',
    data: query
  })
}
// 网站信息
export const getwzxinxiList = (query) => {
  return request({
    method: 'post',
    url: '/api/index/config',
    data: query
  })
}
// 发送短信
export const sendinfo = (query) => {
  return request({
    method: 'post',
    url: '/api/sms/send',
    data: query
  })
}
// 忘记密码
export const forgetmima = (query) => {
  return request({
    method: 'post',
    url: '/api/user/forget',
    data: query
  })
}
// 优惠券
export const getyouhuiquan = (query) => {
  return request({
    method: 'post',
    url: '/api/order/coupon',
    data: query
  })
}
// 领取优惠券
export const getmoney = (query) => {
  return request({
    method: 'post',
    url: '/api/order/receive',
    data: query
  })
}
// 隐藏导航栏列表
export const getycdaohang= (query) => {
  return request({
    method: 'post',
    url: '/api/goods/getHideCategory',
    data: query
  })
}


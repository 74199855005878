import axios from 'axios'
import router from '@/router'
import {
	Dialog,
	Toast
} from 'vant'
import {Message}from 'element-ui'
import {
	url
} from '@/main'

// 创建axios实例
const request = axios.create({
	baseURL: "https://ht.mohewh.com",
	// baseURL: "http://192.168.0.115:8021/",
	// 请求的超时时间
	// timeout: 10000,
	// form、json
	reqDataType: 'json',
	// 是否需要token
	needtoken: true

})

// 请求拦截器
request.interceptors.request.use(function(config) {
	// 在发送请求之前做些什么
	if (config.reqDataType === 'form') {
		config.headers = {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
		var newFormData = new FormData()
		var person = config.data
		for (let key in person) {
			if (Object.prototype.hasOwnProperty.call(person, key)) {
				newFormData.append(key, person[key])
			}
		}
		config.data = newFormData
	} else if (config.reqDataType === 'json') {
		var token = JSON.parse(localStorage.getItem('user_info'));
		token = token?token.token:''
		// if(config.data='')
		if(token){
			config.headers = {
				'Content-Type': 'application/json',
				'token':token
			}
		}else{
			config.headers = {
				'Content-Type': 'application/json',
			}
		}
	} else if (config.reqDataType === 'file') {
		config.headers = {
			'Content-Type': 'multipart/form-data'
		}
	} else {
		console.log('error>>>')
	}
	// 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token
	// 在响应拦截器返回中判断本地token是否过期
	// if (config.needtoken == true) {
	// 	const token = localStorage.getItem('token')
	// 	token && (config.headers.Authorization = 'Bearer ' + token)
	// }
	return config
}, function(error) {
	// 对请求错误做些什么
	return Promise.reject(error)
})

// 响应拦截器
request.interceptors.response.use(function(response) {
	// 对响应数据做点什么
	if (response.data.code == 1 || response.data.code == 0 || response.data.code == -1) {
		return response.data
	} else {
		if (response.data.code) {

			switch (response.data.code) {
				// 401: 未登录
				case 401:
					// '系统提示'，您可以继续留在该页面，或者立即登录
					// 立即登录跳转登录页面，并携带当前页面的路径
					// 在登录成功后返回当前页面，这一步需要在登录页操作
					Toast.clear()
					Dialog.confirm({
							title: '未登录',
							message: '您可以继续留在该页面，或者立即登录',
						})
						.then(() => {
							router.replace({
								path: '/login',
								query: {
									redirect: router.currentRoute.fullPath
								}
							})
						})
						.catch(() => {
							// on cancel
						})
					break;
					// 403 token过期
				case -1:
					// 清除token
					// '系统提示'，登录状态已过期，您可以继续留在该页面，或者重新登录'
					// 重新登录跳转登录页面，并携带当前页面的路径
					// 在登录成功后返回当前页面，这一步需要在登录页操作
					localStorage.removeItem('token')
					Toast.clear()

						// .then(() => {
						// 	router.replace({
						// 		path: '/',
						// 		// query: {
						// 		// 	redirect: router.currentRoute.fullPath
						// 		// }
						// 	})
						// })
						// .catch(() => {
						// 	// on cancel
						// })
					break;
					// 404请求不存在
				case 404:
					Message.fail('网络请求不存在')
					break;
					// 其他错误，直接抛出错误提示
				default:
					Message.fail(response.data.msg)
			}
		}

	}
	return Promise.reject(response.data)
}, function(error) {
	// 对响应错误做点什么
	console.log('err' + error)
	let {
		message
	} = error;
	if (message == "Network Error") {
		message = "后端接口连接异常"
	} else if (message.includes("timeout")) {
		message = "系统接口请求超时"
	} else if (message.includes("Request failed with status code")) {
		message = "系统接口" + message.substr(message.length - 3) + "异常";
	}
	Toast.fail(message)
})

// 导出axios实例
export default request
